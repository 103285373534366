<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/product/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="text-align: center;font-size: 30px;font-weight: bold;margin-top: 30px;">
                参数对比
            </div>
            <div style="margin-top: 30px;" class="toptable">
                <table cellpadding="10px" cellspacing="0px" style="width: 100%;table-layout: fixed;" >
                    <tr style="height: 260px;text-align: left;">
                        <th><span style="margin-left: 20px;">{{$t('productinfo.xuanzexinghao')}}</span></th>
                        <th v-for="(item,index) in 4" :key="item" :style="{'vertical-align': duibilist[index] ? 'top' : ''}" >
                            <div style="width: 220px;height: 139px;background: #fff;margin: 0 auto;position: relative;" v-if="duibilist[index]">
                                <div style="position: absolute;width: 20px;height: 20px;background: #E2E2E2;right: 0px;text-align: center;line-height: 20px;" @click.stop="del(index)">
                                    <i class="el-icon-close"></i>
                                </div>
                                <div style="width: 150px;margin: 0 auto;">
                                    <el-image style="width: 100%;" :src="duibilist[index].image"></el-image>
                                </div>
                                <div style="text-align: center;line-height: 20px;">
                                    {{duibilist[index].product_name}}
                                </div>
                                <div style="text-align: center;font-size: 14px;font-weight: normal;color: rgba(51,51,51,0.6);line-height: 20px;">
                                    {{duibilist[index].product_feature}}
                                </div>
                            </div>
                            <div  v-if="!duibilist[index]">
                                <div style="display: flex;">
                                    <div style="margin-left: 10px;">{{$t('productinfo.fenlei')}}</div>
                                    <el-select size="mini" filterable style="margin-left: 70px;width: 50%;" v-model="classid" :disabled="duibilist.length>0" @change="classchange">
                                        <el-option v-for="(item,index) in classList" :key="index" :value="item.cat_id" :label="item.cat_name"></el-option>
                                    </el-select>
                                </div>
                                <div style="display: flex;margin-top: 20px;">
                                    <div style="margin-left: 10px;">{{$t('productinfo.qingxuanxinghao')}}</div>
                                    <el-select size="mini" filterable style="margin-left: 20px;width: 50%;" v-model="selectid[index]" @change="selectchange" >
                                        <el-option v-for="(item,index) in xilielist" :key="index" :value="item.product_id" :label="item.product_name"></el-option>
                                    </el-select>
                                </div>
                            </div>
                        </th>
                    </tr>
                </table>
            </div>
            <div style="margin-top: 15px;height: 600px;overflow-y: auto;" class="bottomtable">
                <table cellpadding="10px" cellspacing="0px" style="width: 100%; table-layout: fixed;border: 1px solid #E2E2E2;">
                    <tr style="height: 20px;border-bottom: 1px solid #E2E2E2;text-align: left;" v-for="(item,index) in parameterItemList" :key="index">
                        <th style="background: #F9F9F9;"><span style="margin-left: 20px;">{{item.item_title}}</span></th>
                        <th>
                            <div v-if="duibilist[0]" style="white-space: pre-line;">
                                {{getParameteritemvalue(0,item.item_id)}}
                            </div>
                        </th>
                        <th>
                            <div v-if="duibilist[1]" style="white-space: pre-line;" :style="{'color': getParameteritemvalue(1,item.item_id) != getParameteritemvalue(0,item.item_id) ? 'red' : ''}">
                                {{getParameteritemvalue(1,item.item_id)}}
                            </div>
                        </th>
                        <th>
                            <div v-if="duibilist[2]" style="white-space: pre-line;" :style="{'color': getParameteritemvalue(2,item.item_id) != getParameteritemvalue(0,item.item_id) ? 'red' : ''}">
                                {{getParameteritemvalue(2,item.item_id)}}
                            </div>
                        </th>
                        <th>
                            <div v-if="duibilist[3]" style="white-space: pre-line;" :style="{'color': getParameteritemvalue(3,item.item_id) != getParameteritemvalue(0,item.item_id) ? 'red' : ''}">
                                {{getParameteritemvalue(3,item.item_id)}}
                            </div>
                        </th>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { canshuduibi,productsClass,productsList } from "@/api/api"; 
export default {
    data(){
        return{
            duibiProduct:[],
            duibilist:[],
            xilielist:[],
            parameterItemList:[],
            selectid:{},
            classList:[],
            classid:''
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        this.getList()
        this.getclass()
    },
    methods:{
        getList(){
            let duibiProduct = JSON.parse(localStorage.getItem("duibiProduct"))
            this.duibiProduct = duibiProduct
            canshuduibi({duibiProduct:duibiProduct}).then(res=>{
                this.duibilist = res.data.duibilist
                this.xilielist = res.data.xilielist
                this.parameterItemList = res.data.parameterItemList
                this.duibilist.forEach(item=>{
                    item.image = process.env.VUE_APP_BASE_API+item.image
                })
                this.classid = res.data.parent_id
            })
        },
        getclass(){
            productsClass().then(res=>{
                this.classList = res.data
            })
        },
        getParameteritemvalue(index,item_id){
           let object = this.duibilist[index].parameterList.find((item=>item.item_id == item_id))
           if(object){
                return object.para_value
           }else{
                return '/'
           }
        },
        classchange(value){
            this.classid = value
            productsList({cat_id:this.classid,page:1,limit:9999}).then(res=>{
                res.data.list.forEach((item,index)=>{
                    item.images = process.env.VUE_APP_BASE_API+item.get_image_list[0].product_img
                })
                this.xilielist = res.data.list
            })
        },
        selectchange(value){
            this.duibiProduct.push(value)
            localStorage.setItem("duibiProduct",JSON.stringify(this.duibiProduct))
            this.getList()
            this.selectid = {}
        },
        del(index){
            let product_id = this.duibilist[index].product_id
            this.duibiProduct = this.duibiProduct.filter((item) => {return item !== product_id});
            localStorage.setItem("duibiProduct",JSON.stringify(this.duibiProduct))
            this.duibilist.splice(index,1)
        }
    }
}
</script>
<style scoped>
    .toptable table{
        border: 1px solid #E2E2E2;
        background: #F9F9F9;
    }
    .toptable tr td,th{
        border-right: 1px solid #E2E2E2;
    }
    .bottomtable table{
        border-bottom: 0px;;
    }
    .bottomtable tr td,th{
        border-right: 1px solid #E2E2E2;
        border-bottom: 1px solid #E2E2E2;
    }
    @media screen and (max-width: 1280px) {
        .appmain{
            width: 100%;
        }
    }
</style>